<template>
  <div style="">
    <div v-if='codeShow == 1'>
      <div v-for="(item,index) in remarkList" :key="item" class="cardBox">
        <div class="cardBoxHead">
          <h3>数字化电梯物联网安全监管系统 ({{item}})</h3>
        </div>
        <div class="cardBoxContant">
          <div class="boxContant">
            <el-row style="padding: 35px 0 23px">
              <el-col :span="12" class="boxLeft">
                <div class="line">
                  <h3>使用单位：</h3>
                  <span class="titel">{{dioData.propertyUnitName}}</span>
                </div>
                <div class="line">
                  <h3>维保单位： </h3>
                  <span class="titel">{{dioData.maintainUnitName}}</span>
                </div>
                <div class="line">
                  <h3>保险单位： </h3>
                  <span class="titel">{{dioData.insuranceUnitName ? dioData.insuranceUnitName : '-'}}</span>
                </div>
                <div class="line">
                  <h3>救援识别码： </h3>
                  <span class="titel">{{dioData.rescueCode}}</span>
                </div>
                <div class="line">
                  <h3>维保单位电话： </h3>
                  <span class="titel">{{dioData.maintainUnitPhone}}</span>
                </div>
                <div class="line">
                  <h3>物业单位电话： </h3>
                  <span class="titel">{{dioData.propertyUnitPhone}}</span>
                </div>
                <div class="line">
                  <h3>维保人员： </h3>
                  <span class="titel">{{dioData.maintainUserNames && dioData.maintainUserNames.split(';')[0]}}</span>
                </div>
              </el-col>
              <el-col :span="12" style="text-align: center;position: relative;">
                <img :src="require('@/assets/qrcode.png')" style="width: 90%;">
                <Qrcode
                  :url="`https://${url}/scancode/new_file.html?id=${dioData.id}&type=${index === 0 ? '' : (index == 1?'jiaoding' :(index == 2?'jiaodi' : index == 3?'jifang' :'qita'))}`"
                  style="position: absolute; right: 34px; top: 27.5%;" :size="180"></Qrcode>
                <h3 class="tipsTitle">打开微信使用扫一扫<br>获取更多电梯信息</h3>
                <h3 style="color: black;margin-top: -10px;position: absolute;margin-left: 190px;"
                  v-show="dioData.deviceSosSwitch&&JSON.parse(dioData.deviceSosSwitch).wechatCodeSos == 1">进行<span
                    style="color: #D9001B;">一键呼救</span></h3>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="cardBoxBottom">
        </div>
      </div>
    </div>
    <div v-if='codeShow == 2'>
      <div style="margin: 0 auto; width: 1080px; position: relative;">
        <!-- <div style=" height: 720px;"> -->
        <img src="../../assets/wenshang/wenshang.png" style="width: 1080px;height: 720px;position: relative;">
        <div class="wordInfo">{{dioData.rescueCode}}</div>
        <div class="codeOne">
          <Qrcode :url="`https://${url}/scancode/new_file.html?id=${dioData.id}`"
            style="position: absolute;  right: calc(100% - 980px); "
            :style=" dioData.rescueCode ? 'top:-465px' : 'top:-375px'" size="230"></Qrcode>
        </div>
        <!-- </div> -->
      </div>
      <div v-for="(item,index) in 3" :key="item" class="cardBox" style="margin: 0 auto; position: relative;">
        <!-- </div> -->
        <div style="position: relative;width: 720px;height: 720px; margin: 30px auto;">
          <img :src="require(`@/assets/wenshang/wenshang${index + 1}.png`)"
            style="width: 720px;height: 720; margin: 0 auto">
          <Qrcode
            :url="`https://${url}/scancode/new_file.html?id=${dioData.id}&type=${index === 0 ? 'jiaoding' : (index == 1?'dikeng' : 'jifang')}`"
            style="position: absolute; right: calc(50% - 175px); top: calc(50% - 150px); z-index: 99;" size="350">
          </Qrcode>
          <div class="wordInfoOne">{{dioData.rescueCode}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Qrcode from '@/components/qrcode';
  export default {
    components: {
      Qrcode
    },
    data() {
      return {
        url: window.location.hostname == 'localhost' ? 'zxtlw.zyzntech.com' : window.location.hostname,
        dioData: {},
        codes: '',
        codeShow: 0,
        remarkList: ['轿厢', '轿顶', '轿底', '机房', '其它'],
      }
    },
    methods: {},
    beforeCreate() {
      document.querySelector('html').setAttribute('style', 'background-color: #fff!important')
      document.querySelector('#app').setAttribute('style', 'background-color: #fff!important')
    },
    created() {
      console.log(223, this.$route)

      let url1 = '/api/ele/web/elevatorInfo/list'
      this.$http
        .post(url1, {
          id: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.success) {
            console.log(res.data, 'res.data.records[0]');
            this.dioData = res.data.data.records[0]
            this.codes = res.data.data.records[0].areaCodes
            console.log(JSON.parse(this.codes)[1], 'codes')
            console.log(JSON.parse(this.codes).find(item => item => item == 532600000000))
            this.codeShow = JSON.parse(this.codes).find(item => item == 532600000000) && JSON.parse(this.codes).find(
              item => item == 532600000000) != undefined ? 2 : 1
            setTimeout(() => {
              window.print()
            }, 2000)
            console.log(this.dioData, 'dioData');
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
  }
</script>

<style lang="scss" scoped>
  .codeOne {
    text-align: center;
    position: relative;
    // top: -465px;
    // margin-left: -132px;
  }

  .codeTwo {
    text-align: center;
    position: relative;
    // top: -535px;
    // margin-left: -665px;
  }

  .wordInfo {
    position: relative;
    top: -558px;
    right: -430px;
    // margin-top: -560px;
    // margin-left: 431px;
    font-size: 75px;
    font-family: fangsong;
    font-weight: 700;
    color: #FFE500;
  }

  .wordInfoOne {
    position: absolute;
    top: 60px;
    // left: calc(50% - 130px);
    width: 720px;
    text-align: center;
    // margin-top: -680px;
    // margin-left: 231px;
    font-size: 75px;
    font-family: fangsong;
    font-weight: 700;
    color: #FFE500;
  }

  .wordInfoTwo {
    position: absolute;
    top: 8%;
    left: 240px;
    // margin-top: -680px;
    // margin-left: 231px;
    font-size: 75px;
    font-family: fangsong;
    font-weight: 700;
    color: #FFE500;
    z-index: 999;
  }

  .wordInfoThree {}

  .cardBox {
    margin: 35px auto 80px;
    background: #fff;
    width: 1080px;

    .cardBoxHead {
      background: #2F673C;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        font-size: 35px;
        letter-spacing: 7px;
        margin: 25px 0px;
      }
    }

    .cardBoxContant {
      background: #D9D385;

      .boxContant {
        width: 90%;
        margin: 0 auto;

        .boxLeft {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 480px;

          .line {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .titel {
            padding-bottom: 10px;
            border-bottom: 2px solid #000;
            letter-spacing: 3px;
            padding-left: 10px;
            width: 68%;
            display: inline-block;
            font-size: 17px;
            font-weight: bolder;
          }
        }

        .tipsTitle {
          font-weight: bolder;
          line-height: 1.8em;
          font-size: 22px;
        }
      }
    }

    .cardBoxBottom {
      height: 20px;
      background: #2F673C;
    }
  }

  @media print {
    // .wordInfoTwo {
    //   position: absolute;
    //   top: 42%;
    //   left: 240px;
    //   // margin-top: -680px;
    //   // margin-left: 231px;
    //   font-size: 75px;
    //   font-family: fangsong;
    //   font-weight: 700;
    //   color: #FFE500;
    //   z-index: 999;
    // }

    // body * {
    // visibility: hidden;
    // }
    // #app * {
    // visibility: visible;
    // }
    // #app {
    // background: white;
    // }
  }
</style>